<template>
  <div>
    <AppHeader />
    <v-layout wrap justify-center>
      <v-flex xs12 sm12 md2 lg2 xl2 hidden-sm-and-down>
        <v-navigation-drawer
          class="fixedSidebar"
          v-model="sideNav"
          height="100vh"
          width="100%"
          permanent
          fixed
          clipped
          color="white"
        >
          <v-layout wrap>
            <v-flex xs12>
              <v-list>
                <v-list v-for="main in menus2" :key="main._id">
                  <v-layout wrap v-if="main.subMenu">
                    <v-flex xs12>
                      <v-list-group no-action :value="false">
                        <template v-slot:activator>
                          <v-layout wrap justify-center>
                            <v-flex xs12 sm1 pl-2>
                              <v-list-item-icon>
                                <v-img
                                  height="20px"
                                  contain
                                  :src="main.src"
                                ></v-img>
                              </v-list-item-icon>
                            </v-flex>
                            <v-flex xs6 sm8 text-center pl-8 pt-5      :class="{ mainbg6: $route.path === main.name && $route.path === sub.route }">
                              <v-list-item-title
                                style="
                                  font-size: 16px;
                                  color: black;
                                  cursor: pointer;
                                  font-family: mainfont;
                                "
                                >{{ main.name }}
                              </v-list-item-title></v-flex
                            >
                          </v-layout>
                        </template>
                        <template v-for="sub in main.subMenu">
                          <v-flex
                            xs12
                            text-right
                            pl-4
                            :key="sub._id"
                            style="text-decoration: none"
                            :class="{ mainbg6: $route.path === sub.route }"
                          >
                            <v-layout wrap justify-center >
                              <v-flex xs6 pa-2 text-left 
                              >
                                <router-link
                                  :to="sub.route"
                                  style="text-decoration: none"
                                >
                                  <span
                                    :style="
                                      $route.path == sub.route
                                        ? {
                                            'font-family': 'mainfont',
                                            'font-size': ' 14px',
                                          }
                                        : {
                                            'font-family': 'mainfont',
                                            'font-size': ' 14px',
                                          }
                                    "
                                    style="
                                      font-size: 15px;
                                      color: black;
                                      cursor: pointer;
                                      font-family: mainfont;
                                    "
                                  >
                                    {{ sub.name }}
                                  </span>
                                </router-link>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 :key="sub._d"> </v-flex>
                        </template>
                      </v-list-group>
                      <!-- <v-list-group v-else> -->
                    </v-flex>
                  </v-layout>
                  <v-layout wrap justify-end v-else pa-1>
                    <v-flex
                      xs12
                      :class="{ mainbg5: $route.path === main.route }"
                    >
                      <v-list-item-group active-class="activated">
                        <router-link
                          :to="main.route"
                          style="text-decoration: none"
                        >
                          <v-layout wrap class="content" justify-end>
                            <v-flex xs12 pr-3 sm2 align-self-center text-center>
                              <v-img
                                height="20px"
                                contain
                                :src="main.src"
                              ></v-img>
                            </v-flex>

                            <v-flex
                              xs12
                              sm8
                              pl-2
                              pt-1
                              align-self-center
                              text-left
                            >
                              <span
                                :style="
                                  $route.path === main.route
                                    ? {
                                        'font-family': 'mainfont',
                                        'font-size': '16px',
                                        color: 'black !important',
                                      }
                                    : {
                                        'font-family': 'mainfont',
                                        'font-size': '16px',
                                      }
                                "
                                style="
                                  font-size: 18px;
                                  color: black;
                                  cursor: pointer;
                                  font-family: mainfont;
                                "
                              >
                                {{ main.name }}
                              </span>
                            </v-flex>
                          </v-layout>
                        </router-link>
                      </v-list-item-group>
                    </v-flex>

                    <v-divider></v-divider>
                  </v-layout>

                  <!-- </v-list-group> -->
                </v-list>
              </v-list>
            </v-flex>
          </v-layout>
        </v-navigation-drawer>
        <!-- </div> -->
      </v-flex>
      <v-flex xs12 sm12 md10 lg10 xl10 pt-lg-0>
        <v-layout
          fill-height
          wrap
          justify-center
          :style="$route.name == 'Dashboard'"
        >
          <v-flex xs12>
            <v-card
              :color="$route.name == 'Dashboard' ? 'transparent' : 'white'"
              :flat="$route.name == 'Dashboard' ? true : true"
            >
              <v-layout class="mainbg4" wrap justify-center>
                <v-flex xs12>
                  <router-view></router-view>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";

import AppHeader from "@/components/Common/appHeader";
export default {
  components: {
    AppHeader,
  },
  data() {
    return {
      sideNav: true,
      list: {},
      menus2: [
        {
          name: "Home",
          route: "/DashboardPage",
          src: require("../../assets/Images/sicon1png.png"),
        },
        {
          name: "Analytics",
          route: "/AnalyticsPage",
          src: require("../../assets/Images/sicon16.png"),
        },
        {
          name: "Auctions",
          route: "/AuctionsPage",
          src: require("../../assets/Images/sicon14.png"),
        },
        {
          name: "Live Auction",
          route: "/LiveAuctionsPage",
          src: require("../../assets/Images/sicon10.png"),
        },

        {
          name: "Category",
          route: "/categoryPage",
          src: require("../../assets/Images/sicon2.png"),
        },
        {
          name: " Bag Type",
          route: "/BagType",
          src: require("../../assets/Images/sicon2.png"),
        },
        {
          name: "Blogs",
          route: "/home2",
          src: require("../../assets/Images/sicon4.png"),
        },

        {
          name: "Lot's",
          route: "/LotsPage",
          src: require("../../assets/Images/sicon8.png"),

        },

        // {
        //   name: "Lots",

        //   subMenu: [
        //     {
        //       name: "All ",
        //       route: "/LotsPage",
        //     },
        //     {
        //       name: "Upcomming ",
        //       route: "/Upcomminglots",
        //     },
        //     {
        //       name: "Live ",
        //       route: "/Upcomminglots",
        //     },
        //   ],
        // },
        {
          name: "News",
          route: "/NewsPage",
          src: require("../../assets/Images/sicon13.png"),
        },

        {
          name: "Users",
          route: "/ActiveUsersPage",
          src: require("../../assets/Images/sicon6.png"),
        },

        {
          name: "Store",
          route: "/StorePage",
          src: require("../../assets/Images/sicon3.png"),
        },

        {
          name: "Terms and Conditions",
          route: "/TermsAndConditions",
          src: require("../../assets/Images/terms.png"),
        },

        {
          name: "Return Policy",
          route: "/ReturnPolicy",
          src: require("../../assets/Images/return.png"),
        },

        {
          name: "Cancellation Policy",
          route: "/CancellationPolicy",
          src: require("../../assets/Images/cancellation.png"),
        },
        {
          name: "Shipping Policy",
          route: "/ShippingPolicy",
          src: require("../../assets/Images/shipping.png"),
        },


        {
          name: "Refund Policy",
          route: "/RefundPolicy",
          src: require("../../assets/Images/refund.png"),
        },


        {
          name: "Privacy Policy",
          route: "/PrivacyPolicy",
          src: require("../../assets/Images/privacy.png"),
        },
      ],

      menus3: [
        {
          name: "Home",
          route: "/DashboardPage",
          src: require("../../assets/Images/sicon1png.png"),
        },
        {
          name: "Auctions",
          route: "/AuctionsPage",
          src: require("../../assets/Images/sicon1png.png"),
        },

        {
          name: "Category",
          route: "/categoryPage",
          src: require("../../assets/Images/sicon2.png"),
        },

        {
          name: "Add Bag Type",
          route: "/BagType",
          src: require("../../assets/Images/sicon2.png"),
        },
        {
          name: "Blogs",
          route: "/home2",
          src: require("../../assets/Images/sicon13.png"),
        },

        {
          name: "Lots",
          route: "/LotsPage",
          src: require("../../assets/Images/sicon8.png"),
        },
        {
          name: "News",
          route: "/NewsPage",
          src: require("../../assets/Images/sicon4.png"),
        },

        {
          name: "Users",
          route: "/ActiveUsersPage",
          src: require("../../assets/Images/sicon6.png"),
        },
        {
          name: "Store",
          route: "/ActiveUsersPage",
          src: require("../../assets/Images/sicon3.png"),
        },
        {
          name: "Terms and Conditions",
          route: "/TermsAndConditions",
          src: require("../../assets/Images/terms.png"),
        },

        {
          name: "Return Policy",
          route: "/ReturnPolicy",
          src: require("../../assets/Images/return.png"),
        },

        {
          name: "Cancellation Policy",
          route: "/CancellationPolicy",
          src: require("../../assets/Images/cancellation.png"),
        },
        {
          name: "Shipping Policy",
          route: "/ShippingPolicy",
          src: require("../../assets/Images/shipping.png"),
        },


        {
          name: "Refund Policy",
          route: "/RefundPolicy",
          src: require("../../assets/Images/refund.png"),
        },


        {
          name: "Privacy Policy",
          route: "/PrivacyPolicy",
          src: require("../../assets/Images/privacy.png"),
        },
      
      ],
    };
  },
  computed: {
    role() {
      return this.$store.state.role;
    },
  },
  methods: {
    getList() {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/authenticate/admin",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          var role = response.data.role;
          localStorage.setitem("role", role);
          this.list = response.data;
          this.appLoading = false;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
/* .activated { */
.activated {
  color: white !important;
  /* font-size:40px  !important; */
}

.demo-bg {
  opacity: 0.6;
}
.mainbg2 {
  background-image: linear-gradient(269.6deg, #e7f1f0 -31.66%, #e7f1f0);
}

.item-text {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: black;
  cursor: pointer;
  font-family: mainfont;
  margin-left: 8px; /* Adjust the margin as needed */
}

.fixedSidebar {
  /* position: fixed; */

  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 50;
}

@media only screen and (min-device-width: 360px) and (max-device-width: 640px) {
  .fixedSidebar {
    display: none !important;
  }
}
.
  @media
  only
  screen
  and
  (min-device-width: 360px)
  and
  (max-device-width: 640px) {
  .fixedSidebar {
    display: none !important;
  }
}

.content {
  /* Other styles */
  height: 40%; /* Set the height to 100% of the parent container */
}
/* #appNavbar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 200px;
  background-color: #f2f2f2;
  overflow-y: auto;
} */

/* } */
.mainbg5 {
  background-image: linear-gradient(269.6deg, #e7f1f0 -31.66%, #e7f1f0);
  padding: 5px;
}
.mainbg6 {
  background-image: linear-gradient(269.6deg, #e7f1f0 -31.66%, #e7f1f0);
  padding: 5px;
}
.activated .content {
    background-color: #f5f5f5;
  }
  .activated .mainbg6 {
    background-color: #eeeeee;
  }
</style>